import { Link } from "gatsby";
import { FetchImage, getPublicUrl } from "../FetchImage";
import * as styles from "./cards.module.css";
import React from "react";
import { BannerTag } from "../buttons/Buttons";
import { baseUrl, RenderSvg, ScreenSize } from "../../utils/helpers/helper";
import { GatsbyImage } from "gatsby-plugin-image";

export const PrimaryCrad = React.memo(
  ({
    Text,
    circleCard,
    image,
    video,
    CardActive,
    embedCode,
    className,
    platformCard,
    ...rest
  }) => {
    const RenderData = rest?.to ? Link : "div";
    return (
      <RenderData
        className={`${styles?.PrimaryCrad} ${
          CardActive ? styles?.PrimaryCradActive : ""
        }  ${circleCard ? styles?.circleCard : ""} ${
          className || ""
        } ${className}`}
        {...rest}
      >
        {embedCode ? (
          <div
            className={`${styles?.PrimaryCradEmbed} ${video?.className || ""}`}
            {...embedCode}
          >
            loadingEmbed
          </div>
        ) : image ? (
          image.gatsbyImageData ? (
            <div className={styles?.GatsbyImageContainer}>
              <GatsbyImage image={image.gatsbyImageData} alt="image" />
            </div>
          ) : (
            <img
              {...{
                src: image?.src
                  ? image?.src
                  : getPublicUrl(image?.folder, image?.mediaName),
              }}
              className={`${styles?.PrimaryCradImg} ${image?.className || ""}`}
              alt={image?.mediaName || "image"}
            />
          )
        ) : (
          <video
            autoPlay
            loop
            muted
            playsInline
            controls={false}
            className={`${styles?.PrimaryCradVideo} ${video?.className || ""}`}
            {...video}
          />
        )}
        <p
          style={{
            display: Text ? "flex" : "none",
            fontSize: "1rem",
            fontWeight: "500",
          }}
          className={`${styles?.PrimaryCradText} ${
            Text ? styles?.isPlatform : {}
          } `}
        >
          {Text}
          <p style={{ fontSize: "1rem", fontWeight: "500" }}>
            {rest?.readTime}
          </p>
        </p>
      </RenderData>
    );
  }
);

export const PrimaryCradTitleText = ({ Text, ...rest }) => {
  return (
    <p className={`${styles?.PrimaryCradTitleText} ${rest?.className || ""}`}>
      {Text}
    </p>
  );
};

export const SecondaryCard = ({
  Text,
  image,
  video,
  CardActive,
  embedCode,
  media,
  ...rest
}) => {
  return (
    <div className={styles?.SecondaryCard} {...rest}>
      {embedCode ? (
        <div
          className={`${styles?.SecondaryCardEmbed} ${video?.className || ""}`}
          {...embedCode}
        >
          loadingEmbed
        </div>
      ) : image ? (
        image.gatsbyImageData ? (
          <div className={styles?.GatsbyImageContainer}>
            <GatsbyImage image={image.gatsbyImageData} alt="image" />
          </div>
        ) : (
          <div
            className={`${styles?.SecondaryCardImg} ${image?.className || ""}`}
          >
            <img
              src={getPublicUrl(image?.folder, image?.mediaName)}
              alt={image?.mediaName || "image"}
            />
          </div>
        )
      ) : media ? (
        <div
          className={`${styles?.SecondaryCardImg} ${image?.className || ""}`}
        >
          {media.gatsbyImageData ? (
            <div className={styles?.GatsbyImageContainer}>
              <GatsbyImage image={media.gatsbyImageData} alt="image" />
            </div>
          ) : (
            <img src={baseUrl + media.url} alt="info card" />
          )}
        </div>
      ) : (
        <video
          autoPlay
          loop
          muted
          playsInline
          controls={false}
          className={`${styles?.SecondaryCardVideo} ${video?.className || ""}`}
          {...video}
        />
      )}
    </div>
  );
};

export const PlatformBannerCard = ({ title, description, bgSrc, ...rest }) => {
  const RenderDiv = rest?.to ? Link : "div";
  return (
    <RenderDiv className={styles?.PlatformBannerCard} {...rest}>
      {bgSrc && (
        <>
          <div className={styles?.overlayDiv}></div>
          <div className={styles?.PlatformBannerCardBg}>
            {
              <div className={styles?.GatsbyImageContainer}>
                <GatsbyImage image={bgSrc} alt="image" />
              </div>
            }
          </div>
        </>
      )}
      <div className={styles?.PlatformBannerCardTextDiv}>
        <p className={styles?.PlatformBannerCardText}>{title}</p>
        {!ScreenSize.isMobile() && (
          <p
            className={styles?.PlatformBannerCardSubText}
            style={{ paddingBottom: description?.length <= 30 ? "15px" : "0 " }}
          >
            {description?.substring(0, 80)}
          </p>
        )}
      </div>
    </RenderDiv>
  );
};

export const UseCaseBannerCard = ({ title, ...rest }) => {
  return (
    <div
      className={`${styles?.UseCaseBannerCard} banner-secondary-sm`}
      {...rest}
    >
      {title}
    </div>
  );
};

export const SecondaryCircleCard = ({
  Text,
  image,
  video,
  CardActive,
  embedCode,
  circleImage,
  ...rest
}) => {
  const RenderDiv = rest?.to ? Link : "div";
  return (
    <RenderDiv className={styles?.SecondaryCircleCrad} {...rest}>
      {embedCode ? (
        <div
          className={`${styles?.SecondaryCircleCardEmbed} ${
            video?.className || ""
          }`}
          {...embedCode}
        >
          loadingEmbed
        </div>
      ) : image ? (
        image.gatsbyImageData ? (
          <div
            className={`${styles?.GatsbyImageContainer} ${
              circleImage ? styles?.GatsbyImageCircleImage : ""
            }`}
          >
            <GatsbyImage image={image.gatsbyImageData} alt="image" />
          </div>
        ) : (
          <div
            className={`${styles?.SecondaryCircleCardImg} ${
              image?.className || ""
            }`}
          >
            <img
              src={
                image?.src
                  ? image?.src
                  : getPublicUrl(image?.folder, image?.mediaName)
              }
              alt={image?.mediaName || "image"}
            />
            {/* <FetchImage mediaName={image?.mediaName} folder={image?.folder} /> */}
          </div>
        )
      ) : (
        <video
          autoPlay
          loop
          muted
          playsInline
          controls={false}
          className={`${styles?.SecondaryCircleCardVideo} ${
            video?.className || ""
          }`}
          {...video}
        />
      )}
    </RenderDiv>
  );
};

export const PrimarySquareCard = ({
  Text,
  image,
  video,
  tagText,
  showMinute,
  title,
  className,
  embedCode,
  ...rest
}) => {
  const RenderData = rest?.to ? Link : "div";

  return (
    <RenderData
      className={`${styles?.PrimarySquareCard} ${className}`}
      {...rest}
    >
      {embedCode ? (
        <div
          className={`${styles?.PrimarySquareCardEmbed} ${
            video?.className || ""
          }`}
          {...embedCode}
        >
          loadingEmbed
        </div>
      ) : image ? (
        image.gatsbyImageData ? (
          <div className={styles?.GatsbyImageContainer}>
            <GatsbyImage image={image.gatsbyImageData} alt="image" />
          </div>
        ) : (
          <div
            className={`${styles?.PrimarySquareCardImg} ${
              image?.className || ""
            }`}
          >
            <img
              style={
                image?.src
                  ? { borderRadius: "10px" }
                  : { position: "relative !important" }
              }
              src={
                image?.src
                  ? image?.src
                  : getPublicUrl(image?.folder, image?.mediaName)
              }
              className={image?.src ? "" : styles?.PrimarySquareCardImg}
              alt={image?.mediaName || "image"}
            />
          </div>
        )
      ) : (
        <video
          autoPlay
          loop
          muted
          playsInline
          controls={false}
          className={`${styles?.PrimarySquareCardVideo} ${
            video?.className || ""
          }`}
          {...video}
        />
      )}
      <div className={styles?.PrimarySquareCardOverlayDiv}>
        <p className={`body-sm ${styles?.PrimarySquareCardTitle}`}>{title}</p>
        <div className={styles?.PrimarySquareCardOverlayText}>
          <BannerTag tagText={tagText} />
          {showMinute && <p className={`${styles?.minutes}`}>{showMinute}</p>}
        </div>
      </div>
    </RenderData>
  );
};

export const SecondarySquareCard = ({ image, video, embedCode, ...rest }) => {
  const RenderData = rest?.to ? Link : "div";

  return (
    <RenderData className={styles?.SecondarySquareCard} {...rest}>
      {embedCode ? (
        <div
          className={`${styles?.SecondarySquareCardEmbed} ${
            video?.className || ""
          }`}
          {...embedCode}
        >
          loadingEmbed
        </div>
      ) : image ? (
        image.gatsbyImageData ? (
          <div className={styles?.GatsbyImageContainer}>
            <GatsbyImage image={image.gatsbyImageData} alt="image" />
          </div>
        ) : (
          <div
            className={`${styles?.SecondarySquareCardImg} ${
              image?.className || ""
            }`}
          >
            <img
              src={
                image?.src
                  ? image?.src
                  : getPublicUrl(image?.folder, image?.mediaName)
              }
              alt={image?.mediaName || "image"}
            />
          </div>
        )
      ) : (
        <video
          autoPlay
          loop
          muted
          playsInline
          controls={false}
          className={`${styles?.SecondarySquareCardVideo} ${
            video?.className || ""
          }`}
          {...video}
        />
      )}
    </RenderData>
  );
};

export const StoryCrad = ({
  description,
  title,
  image,
  className,
  ...rest
}) => {
  return (
    <div className={`${styles?.StoryCrad} ${className || ""}`} {...rest}>
      <h1 className={styles?.StoryCradTitleText}>{title}</h1>
      <p className={styles?.StoryCradText}>{description}</p>
      {image.gatsbyImageData ? (
        <div className={styles?.StoryCradImage}>
          <div className={styles?.GatsbyImageContainer}>
            <GatsbyImage image={image.gatsbyImageData} alt="image" />
          </div>
        </div>
      ) : (
        <img
          className={styles?.StoryCradImage}
          src={image?.src}
          alt="company card"
        />
      )}
    </div>
  );
};

export const PeopleCard = ({ embedCode, image, video, ...rest }) => {
  const RenderDiv = rest?.to ? Link : "div";

  return (
    <RenderDiv className={styles?.peopleCardContainer} {...rest}>
      {embedCode ? (
        <div className={video?.className} {...embedCode}>
          loadingEmbed
        </div>
      ) : image ? (
        <div
          className={`${styles?.peopleCardImgDiv} ${image?.className || ""}`}
        >
          <FetchImage
            mediaName={image?.mediaName}
            folder={image?.folder}
            alt="Person"
          />
        </div>
      ) : (
        video && (
          <video
            autoPlay
            loop
            muted
            playsInline
            controls={false}
            className={video?.className}
            {...video}
          />
        )
      )}
    </RenderDiv>
  );
};

export const ClientsCard = ({
  Text,
  circleCard,
  image,
  video,
  CardActive,
  embedCode,
  className,
  platformCard,
  socialLinks,
  ...rest
}) => {
  return (
    <>
      <div className={styles.iconContainer}>
        <div className={styles.imgContainer}>
          <div className={styles.clientsCircleCard}>
            {image && image.gatsbyImageData ? (
              <GatsbyImage image={image.gatsbyImageData} alt="Client" />
            ) : (
              <img src={image} alt="Client" />
            )}
          </div>
          {/* <div className={styles.icon}>
            <a href={socialLinks?.url} target="_blank">
              <RenderSvg
                IconName={"linkedin"}
                IconType={"brands"}
                style={{
                  height: "16px",
                  width: "16px",
                  transform: "translateY(2px)",
                }}
              />
            </a>
          </div> */}
        </div>
      </div>
    </>
  );
};

export const CaseStudyCard = ({
  Text,
  subTitle,
  circleCard,
  image,
  video,
  CardActive,
  embedCode,
  className,
  bgColor,
  CaseStudyCard,
  iconName,
  ...rest
}) => {
  const RenderData = rest?.to ? Link : "div";

  const iconSize = ScreenSize.isDesktop() ? "50px" : "30px";
  const knowmoreIcon = ScreenSize.isDesktop() ? "30px" : "20px";

  const maxHeight = rest.content
    ? ScreenSize.isDesktop()
      ? "380px"
      : "280px"
    : "none";

  const maxWidth = rest.content
    ? ScreenSize.isDesktop()
      ? "100%"
      : "100%"
    : "none";

  return (
    <RenderData
      className={`${styles?.caseStudyCard} ${
        CardActive ? styles?.PrimaryCradActive : ""
      }  ${styles?.caseStudyCard} `}
      {...rest}
      style={{ background: bgColor, maxHeight: maxHeight, maxWidth: maxWidth }}
    >
      {iconName && (
        <div>
          <RenderSvg
            IconName={iconName}
            IconType="case"
            style={{ height: iconSize, width: iconSize }}
          />
        </div>
      )}
      <div>
        <p className={styles.caseStudyCardTitle}>{Text}</p>
        <p className={styles.caseStudyCardDesc}>{subTitle}</p>
      </div>
      <div className={styles.readTime}>{rest?.readTime}</div>
      <div
        className={`${styles.caseStudyExplore} ${styles.caseStudyCardTitle}`}
      >
        <p>Explore</p>
        <RenderSvg
          IconName="arrow-circle-right"
          IconType="light"
          style={{
            height: knowmoreIcon,
            width: knowmoreIcon,
            fill: "white",
          }}
        />
      </div>
    </RenderData>
  );
};
