import { Link } from "gatsby";
import React from "react";
import { RenderSvg } from "../../helpers/helper";

const MenuBar = ({
  headermenu,
  polics_terms,
  companylogo,
  handleMenu,
  styles,
  copyRights,
  companyLogoMenu,
  SocialMediaLinks,
  handleProductsClick,
}) => {
  const handleProducts = () => {
    handleProductsClick(true);
  };

  //usecase will be removed and products component will come
  const componentOrder = [
    "home",
    "usecases",
    "platform",
    // "ai-at-kpoint",
    "content-center",
    "about",
    "resources",
    "careers",
    "contact-us",
  ];

  const sortedContent = [...headermenu?.items].sort((a, b) => {
    return componentOrder.indexOf(a.slug) - componentOrder.indexOf(b.slug);
  });

  return (
    <div className={styles?.mobileMenuWrap}>
      <div className={styles?.navbar}>
        <ul className={styles?.pageLink}>
          {sortedContent?.map((data, index) => {
            const { slug: pageName, page } = data;

            const linkPath =
              pageName === "privacy" || pageName === "compilance"
                ? null
                : `/${pageName}`;

            let displayTitle = page?.Title || pageName;
            if (displayTitle === "usecases" || displayTitle === "Use Cases") {
              displayTitle = "products";
            }

            if (displayTitle.toLowerCase() === "products") {
              return (
                <li key={index}>
                  <a href="#" onClick={handleProducts}>
                    {displayTitle}
                    <RenderSvg
                      IconName="angle-down"
                      IconType="light"
                      style={{
                        fill: "white",
                        height: "24px",
                        width: "24px",
                        transform: "translate(10px, 0px)",
                      }}
                    />
                  </a>
                </li>
              );
            }
            return (
              <React.Fragment key={index}>
                {linkPath && (
                  <li key={index}>
                    <Link to={linkPath} onClick={() => handleMenu()}>
                      {displayTitle}
                    </Link>
                  </li>
                )}
              </React.Fragment>
            );
          })}
        </ul>
      </div>
      <div className={styles?.SocialMediaLinks}>
        {SocialMediaLinks?.map((e, i) => {
          const { url, label, OpenInNewTab } = e;
          return (
            <a
              key={i}
              onClick={() => handleMenu()}
              href={url}
              target={OpenInNewTab ? "_blank" : "_self"}
              rel="noopener noreferrer"
            >
              <RenderSvg
                IconName={label}
                IconType={"brands"}
                style={{ height: "25px" }}
              />
            </a>
          );
        })}
      </div>
      <div className={styles?.mobileMenuRules}>
        <div className={styles?.copyrights}>
          <p>{copyRights}</p>
        </div>
        <div className={styles?.policyTerms}>
          {polics_terms?.map((data, index) => {
            const { label, OpenInNewTab } = data;

            return (
              <React.Fragment key={index}>
                <Link
                  onClick={() => handleMenu()}
                  style={{ textTransform: "capitalize" }}
                  target={OpenInNewTab ? "_blank" : "_self"}
                  to={"/" + label}
                  rel="noopener noreferrer"
                >
                  {label === "privacy-policy"
                    ? "Privacy Policy"
                    : label === "terms-of-use"
                    ? "Terms & Conditions"
                    : label}
                </Link>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MenuBar;
